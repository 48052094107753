

































import { mapActions, mapGetters } from 'vuex'
import UserAvatar from '@/components/common/UserAvatar/UserAvatar.vue'
import { PageTitle } from '@/components/atoms/PageTitle'
import { LoadMoreButton } from '@/components/molecules/LoadMoreButton'

export default {
  name: 'Users',
  components: {
    UserAvatar,
    PageTitle,
    LoadMoreButton,
  },
  inject: ['enums'],
  data() {
    return {
      tableHeaders: [
        { text: '', value: 'avatar', sortable: false },
        { text: this.$t('user.table.name'), value: 'name', sortable: false },
        { text: this.$t('user.table.email'), value: 'email' },
        {
          text: this.$t('user.table.organisation'),
          value: 'organisation',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('user', {
      users: 'getUsers',
      tableDataNext: 'getTableDataNext',
      isLoadingNext: 'getIsLoadingNext',
      isLoading: 'getIsLoading',
    }),
  },
  async created() {
    await this.fetchUsers()
  },
  methods: {
    ...mapActions('user', ['fetchUsers']),
    onRowClick(row: any) {
      this.$router.push({ name: 'user', params: { id: row.id } })
    },
  },
}
